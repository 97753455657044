<template>
  <div>
    <Header/>

    <div class="container error">
      <h2>{{textError}}</h2>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header'

  export default {
    name: "Error",

    components:{
      Header
    },

    data(){
      return{
        errorTypeMessage: {
          "rateFound": "Já existe uma avaliação para este protocolo de atendimento! Voce não pode faze-la novamente.",
          "supportError": "Protocolo de atendimento não encontrado!",
          "withoutProtocolError": "Protocolo de atendimento não informado! Contate a Futura Sistemas.",
          "genericError": "Ocorreu um erro! Contate a Futura Sistemas.",
          "setRateError": "Ocorreu um problema ao enviar a avaliação!",
        },

        textError : '',
      }
    },

    created(){
      const router  = this.$router;
      const route  = this.$route;

      this.textError =  this.errorTypeMessage[route.params.typeError];
    }
  }
</script>

<style scoped>
  .container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  h2{
    text-align: center;
  }
</style>
